import React from 'react'
import styled from 'styled-components'
import 'typeface-abril-fatface'
import './layout.css'

export const FullScreen = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export const Container = (props) => (
  <FullScreen>
    { props.children }
  </FullScreen>
)
