import React from 'react'
import {
  Container
} from '../components/container'

export default () => (
  <Container>
    <h1>NOT FOUND</h1>
    <p>You just hit a page that doesn&#39;t exist... that is not sweet.</p>
  </Container>
)
